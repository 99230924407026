<template>
    <span>Redirecting...</span>
    <!-- Intentionally left blank for redirection -->
  </template>
  
  <script>
  export default {
    name: 'ExternalRedirect',
    mounted() {
      window.location.href = 'https://www.rafflebox.ca/raffle/caledondia-mha?fbclid=IwAR3tfQ9W0WqCjW35OiIzHdPnOhriz5B8CPYqpZFSiJf6Y3uSMdpLgzcNqgM_aem_AdKEmXYorXlnnxUkllC1V-52wIJmpGe-sSJD8XXTMD8oRmMZX0fjoTPMjHKUeshm6DGrS_MAy217q1OTa166W4L5';
    }
  }
  </script>