<template>
	<div>
		<div class="upcoming-at-the-park" style="min-height: 200px;">
			<div class="wrapper">
				<div class="upcoming-header">
					Fall Ball
				</div>
			</div>
		</div>
		<div class="main-content">
			<div class="wrapper">
					<img src="https://cdn.blacktiecollab.com/henningpark.com/fallballnew.png" width="100%" alt="">
				
					<a href="https://tally.so/r/mZ8zpB?transparentBackground=1" style="font-size: 20px;" class="button">Fill Out This Form</a>
					
					<h2>Frequently Asked Questions</h2>
					<p><b>What does pay per win mean?</b></p>
					<p>It means you earn money back to your payout kitty every time you win a game, or lose a game. During the regular season games you'll earn $20 per win, and $10 per loss.</p>
					<p><b>What happens if you forfeit?</b></p>
					<p>The winning team still wins $20, the losing team still wins $10, but the forfeiting team will be charged a $40 fee for forfeiting, effectively shrinking their kitty. Forfeit fees will be donated to the park.</p>
					<p><b>What happens if a set of games is rained out?</b></p>
					<p>Games cancelled by the park are rare, but if it happens, both teams will be paid out like a win.</p>
					<p><b>Do playoff games pay out more?</b></p>
					<p>Yes, playoff games pay out at $40 per win and $20 per loss.</p>
					<p style="text-align: center;">---</p>
					<p style="text-align: center;">If you're interested in registering for Fall Ball fill out the form below!</p>
					<a href="https://tally.so/r/mZ8zpB?transparentBackground=1" style="font-size: 20px;" class="button">Fill Out This Form</a>
					<br><br><br><br><br><br>
					<br><br><br><br><br><br>
					<br><br><br><br><br><br>
					<!-- <div v-for="event of events" :key="event" class="event">
						<img :src="event.poster_url" style="max-width: 100%" alt="">
						<h1>{{ event.title }}</h1>
						<div class="date">
							{{ event.date }}
						</div>
						
						<a target="_blank" :href="event.registration_url" class="button">Click to Contact {{ event.author }}</a>
					</div> -->
		
                    <!-- <div v-if="events.length === 0">
                        <iframe data-tally-src="https://tally.so/r/mZ8zpB?transparentBackground=1" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0" title="Fall Ball"></iframe>
                    </div> -->


				
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
	data() {
		return {
			events: [
				// {
				// 	date: '2023-04-29',
				// 	title: 'Henning Park Park Opener',
				// 	author: 'Shane Heyworth/John Uildersma',
				// 	poster_url: 'https://cdn.blacktiecollab.com/henningpark.com/events/330807610_721137423000524_4181165577767951823_n.jpg',
				// 	registration_url: 'https://www.facebook.com/shane.a.heyworth'
				// },
				// {
				// 	date: '2023-05-06',
				// 	title: 'Dash for Cash',
				// 	author: 'Ken Caron',
				// 	poster_url: '',
				// 	registration_url: 'https://www.facebook.com/kenny.caron.12'
				// },
				// {
				// 	date: '2023-05-13',
				// 	title: 'TBA',
				// 	author: 'Shane Heyworth',
				// 	poster_url: '',
				// 	registration_url: 'https://www.facebook.com/shane.a.heyworth'
				// },
				// {
				// 	date: '2023-05-27',
				// 	title: 'Ladies Big Cash Event',
				// 	author: 'Shane Heyworth',
				// 	poster_url: '',
				// 	registration_url: 'https://www.facebook.com/shane.a.heyworth'
				// },
				// {
				// 	date: '2023-06-10',
				// 	title: 'Dash for Cash',
				// 	author: 'Ken Caron',
				// 	poster_url: '',
				// 	registration_url: 'https://www.facebook.com/kenny.caron.12'
				// },
				// {
				// 	date: '2023-07-08',
				// 	title: 'Dash for Cash',
				// 	author: 'Ken Caron',
				// 	poster_url: '',
				// 	registration_url: 'https://www.facebook.com/kenny.caron.12'
				// },
			]
		};
	},
	components: {

	},
	watch: {
		
	},
	mounted: function() {
		var vm = this
		
		axios.get('/api/tournaments').then(results => {
			if(results.data.success === true) {
				vm.events = results.data.events
			}
		})
	},
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		}
	}
}
</script>

<style lang="scss">
    // @import './styles/Welcome.scss';
</style>
