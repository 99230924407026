<template>
    <div>
        <div class="upcoming-at-the-park header-catch" style="min-height: 200px;">
			<div class="wrapper">
				<div class="upcoming-header">
					Storefront
				</div>
				
			</div>
		</div>
        <div class="main-content">
            <div class="wrapper">
                <div id='collection-component-1677737838722'></div>
                <scriptx type="text/javascript">
                
                (function () {
                var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
                if (window.ShopifyBuy) {
                    if (window.ShopifyBuy.UI) {
                    ShopifyBuyInit();
                    } else {
                    loadScript();
                    }
                } else {
                    loadScript();
                }
                function loadScript() {
                    var script = document.createElement('script');
                    script.async = true;
                    script.src = scriptURL;
                    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                    script.onload = ShopifyBuyInit;
                }
                function ShopifyBuyInit() {
                    var client = ShopifyBuy.buildClient({
                    domain: 'team-gear-canada.myshopify.com',
                    storefrontAccessToken: '48e8418a5c6ab979b3bf22bf5b3254b7',
                    });
                    ShopifyBuy.UI.onReady(client).then(function (ui) {
                    ui.createComponent('collection', {
                        id: '404017184990',
                        node: document.getElementById('collection-component-1677737838722'),
                        moneyFormat: '%24%7B%7Bamount%7D%7D',
                        options: {
                "product": {
                    "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                        "max-width": "calc(25% - 20px)",
                        "margin-left": "20px",
                        "margin-bottom": "50px",
                        "width": "calc(25% - 20px)"
                        },
                        "img": {
                        "height": "calc(100% - 15px)",
                        "position": "absolute",
                        "left": "0",
                        "right": "0",
                        "top": "0"
                        },
                        "imgWrapper": {
                        "padding-top": "calc(75% + 15px)",
                        "position": "relative",
                        "height": "0"
                        }
                    },
                    "button": {
                        "font-family": "Raleway, sans-serif",
                        "font-weight": "bold",
                        ":hover": {
                        "background-color": "#025b9f"
                        },
                        "background-color": "#0265b1",
                        ":focus": {
                        "background-color": "#025b9f"
                        }
                    }
                    },
                    "text": {
                    "button": "Add to cart"
                    },
                    "googleFonts": [
                    "Raleway"
                    ]
                },
                "productSet": {
                    "styles": {
                    "products": {
                        "@media (min-width: 601px)": {
                        "margin-left": "-20px"
                        }
                    }
                    }
                },
                "modalProduct": {
                    "contents": {
                    "img": false,
                    "imgWithCarousel": true,
                    "button": false,
                    "buttonWithQuantity": true
                    },
                    "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0px",
                        "margin-bottom": "0px"
                        }
                    },
                    "button": {
                        "font-family": "Raleway, sans-serif",
                        "font-weight": "bold",
                        ":hover": {
                        "background-color": "#025b9f"
                        },
                        "background-color": "#0265b1",
                        ":focus": {
                        "background-color": "#025b9f"
                        }
                    }
                    },
                    "googleFonts": [
                    "Raleway"
                    ],
                    "text": {
                    "button": "Add to cart"
                    }
                },
                "option": {},
                "cart": {
                    "styles": {
                    "button": {
                        "font-family": "Raleway, sans-serif",
                        "font-weight": "bold",
                        ":hover": {
                        "background-color": "#025b9f"
                        },
                        "background-color": "#0265b1",
                        ":focus": {
                        "background-color": "#025b9f"
                        }
                    }
                    },
                    "text": {
                    "total": "Subtotal",
                    "button": "Checkout"
                    },
                    "googleFonts": [
                    "Raleway"
                    ]
                },
                "toggle": {
                    "styles": {
                    "toggle": {
                        "font-family": "Raleway, sans-serif",
                        "font-weight": "bold",
                        "background-color": "#0265b1",
                        ":hover": {
                        "background-color": "#025b9f"
                        },
                        ":focus": {
                        "background-color": "#025b9f"
                        }
                    }
                    },
                    "googleFonts": [
                    "Raleway"
                    ]
                }
                },
                    });
                    });
                }
                })();
                /*]]>*/
                </scriptx>
            </div>
        </div>
        

    </div>    
</template>

<script>
export default {
    data() {
        return {

        }
        
    },
    mounted: async function() {
        
        
    },
    methods: {
      
    }
}
</script>

