<template>
	<div>
		<div class="upcoming-at-the-park" style="min-height: 200px;">
			<div class="wrapper">
				<div class="upcoming-header">
					Tournaments
				</div>
			</div>
		</div>
		<div class="main-content">
			<div class="wrapper">
				<div class="events">
					<div v-for="event of events" :key="event" class="event">
						<img :src="event.poster_url" style="max-width: 100%" alt="">
						<h1>{{ event.title }}</h1>
						<div class="date">
							{{ event.date }}
						</div>
						
						<a target="_blank" :href="event.registration_url" class="button">Click to Contact {{ event.author }}</a>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
	data() {
		return {
			events: [
				{
					date: '2023-05-18',
					title: 'Sara & Jaggers Stag & Doe',
					author: 'Sara Sohm',
					poster_url: 'https://cdn.blacktiecollab.com/henningpark.com/2024/438888203_10161292016799286_265935281267801780_n.jpg',
					registration_url: 'https://www.facebook.com/events/956015272633813/'
				},
				{
					date: '2023-06-22',
					title: 'Swing for the Fence',
					author: 'Sara Sohm',
					poster_url: 'https://cdn.blacktiecollab.com/henningpark.com/2024/440478248_10161301959259286_1500166395894661476_n.jpg',
					registration_url: 'https://www.facebook.com/events/963795331808434/'
				},
				{
					date: '2023-07-27',
					title: 'Warm Up Time',
					author: 'Sara Sohm',
					poster_url: 'https://cdn.blacktiecollab.com/henningpark.com/2024/440582909_10161301959219286_7593575845452600373_n.jpg',
					registration_url: 'https://www.facebook.com/events/730890209119353/'
				},
				{
					date: '2023-08-10',
					title: 'Wind Down Softball Tournament',
					author: 'Sara Sohm',
					poster_url: 'https://cdn.blacktiecollab.com/henningpark.com/2024/440749039_10161301958899286_3978939636117227076_n.jpg',
					registration_url: 'https://www.facebook.com/events/1364843330883461/'
				},
				{
					date: '2023-08-31',
					title: 'Run for Money',
					author: 'Sara Sohm',
					poster_url: 'https://cdn.blacktiecollab.com/henningpark.com/2024/440782841_10161302404414286_1013065373201009641_n.jpg',
					registration_url: 'https://www.facebook.com/events/1099926307966530/'
				},
				
				
			]
		};
	},
	components: {

	},
	watch: {
		
	},
	mounted: function() {
		var vm = this
		
		axios.get('/api/tournaments').then(results => {
			if(results.data.success === true) {
				vm.events = results.data.events
			}
		})
	},
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		}
	}
}
</script>

<style lang="scss">
    // @import './styles/Welcome.scss';
</style>
