<template>
    <div style="max-width: 1000px; margin: 0 auto;">

      <h1>Diamond 1 Stream</h1>
      <video ref="videoPlayer1" class="video-js" controls autoplay></video>
    
    <br><br>
      <h1>Diamond 5 Stream</h1>
      <video ref="videoPlayer5" class="video-js" controls autoplay></video>
    </div>
  </template>
  
  <script>
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css';
  
  export default {
    name: 'StreamComponent',
    mounted() {
      this.setupPlayer('videoPlayer1', 'https://shinobi.slopitchcentral.com/cb65c8e9903067900e2a6580113ea207/hls/Henning/tcGwXqX6Fp/s.m3u8');
      this.setupPlayer('videoPlayer5', 'https://shinobi.slopitchcentral.com/dc9a688b637242488275b176dd18fa37/hls/Henning/8pKpEJABeR/s.m3u8');

    },
    methods: {
      setupPlayer(ref, source) {
        const player = videojs(this.$refs[ref], {
          controls: true,
          fluid: true,
          autoplay: true,
          muted: true,
          sources: [{
            src: source,
            type: 'application/x-mpegURL' // HLS mime type
          }]
        });
        player.on('error', function() {
            console.error('Video.js Error:', player.error());
        });
      }
    }
  }
  </script>
  
  <style scoped>
  .video-js {
    width: 100%;
  }
  </style>