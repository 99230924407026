<template>
    <div>
        <div class="upcoming-at-the-park header-park">
			<div class="wrapper">
				<div class="upcoming-header">
					Trillium Foundation
				</div>
			</div>
		</div>
        <div class="main-content">
            <div class="wrapper">
                <div class="text-wrapper">
                    <h2><strong>Caledonia Minor Hardball to Install New Fencing at Henning Park</strong></h2>
                    <h3><strong><em>Thanks to $69,500 OTF Grant</em></strong></h3>

                    <img src="https://cdn.blacktiecollab.com/henningpark.com/CleanShot 2023-11-24 at 14.18.11.png" style="width: 600px; max-width: 100%;" alt="">
                    <br><br>
                    <p><strong>Caledonia, ON</strong> - On Friday, local MPP Bobbi Ann Brady met with the team at Caledonia Minor Hardball to announce a $69,500 Capital grant from the Ontario Trillium Foundation (OTF) which was awarded in September.</p>
                    <p>“Any time the Ontario Trillium Foundation can provide more opportunity for physical activities, it’s a win-win situation,” said Haldimand-Norfolk MPP Bobby Ann Brady. “In this instance, the fencing will allow higher level of baseball in a safer environment, while enhancing the park.”</p>
                    <p>Thanks to this 6-month Capital grant from OTF, Caledonia Minor Hardball will be able to address a community need by installing a higher fence on one of the ball diamonds at Henning Park in Caledonia.</p>
                    <p>The project will be completed in the Spring of 2024 and will give local residents and youth more available time to enjoy the sport they love, whether on the field or as part of the audience.</p>
                    <p>The installation of a higher fence not only allows baseball to be played safely for the participants, but also allows the park to add another league and streamline the scheduling of current leagues. Caledonia Minor Hardball is honoured to be the recipient of this funding, as the project could not have been completed without this support from OTF and the Government of Ontario.</p>
                    <p>“The impact of this Community Building Fund grant cannot be underestimated,” said John Uildersma, President of Caledonia Minor Hardball. “Expanding the use of the fields for higher level of baseball will allow users to participate in physical activities in a safe manner all season.”</p>
                    <p>Caledonia Minor Hardball, throughout its history of volunteers, is committed to preserving the green space at Henning Park and ensuring that it provides a safe environment to enjoy sporting activities for many years to come. If you would like to learn more about the Caledonia Minor Hardball, please visit our website at www.henningpark.com.</p>
                    <p>The Ontario Trillium Foundation (OTF), an agency of the Government of Ontario, and one of Canada’s leading granting foundations celebrates 40 years of grant-making in Ontario and making a lasting impact in communities. Last year, OTF invested over $110M into 1,022 community projects and partnerships.</p>
                    <p>Visit <a href="https://otf.ca">otf.ca</a> to learn more.</p>
                    <p><br></p>
                    <p><strong>Contact for Media Inquiries</strong></p>
                    <p>John Uildersma</p>
                    <p>President, Caledonia Minor Hardball</p>
                    <p><a href="mailto:johnnyu99@rogers.com">johnnyu99@rogers.com</a>&nbsp;</p>
                </div>
            </div>
            <!-- <hr> -->
            <!-- <div class="wrapper">
                <div class="text-wrapper">
                    <h2><strong>Community Building Fund Gives Caledonia Minor Hardball<br>Illumination at Henning Park</strong></h2>
                    <h3><strong><em>More time for playing youth baseball in Caledonia</em></strong></h3>
                    <br><br>
                    <p><strong>Caledonia, ON</strong> - In 2022, Caledonia Minor Hardball received a $373,300 grant from the Government of Ontario&rsquo;s Community Building Fund &ndash; Capital stream. This grant, delivered by the Ontario Trillium Foundation over 6 months, supports installation of LED lighting at two baseball fields at Henning Park in Caledonia. The project will be completed in the Spring of 2022 and will give local residents and youth more available time to enjoy the sport they love, whether on the field or as part of the audience.</p>
                    <p>&ldquo;Any time the Ontario Trillium Foundation can provide more opportunity for physical activities, it&rsquo;s a win-win situation,&rdquo; said Haldimand-Norfolk MPP Toby Barrett. &ldquo;In this instance, the lighting will allow expanded hours to provide more opportunities.&rdquo;</p>
                    <p>The installation of lights not only allows baseball to be played safely in the evenings, but also allows the park to add another league and streamline the scheduling of current leagues. Caledonia Minor Hardball is honoured to be the recipient of this funding, as the project could not have been completed without this support from OTF and the Government of Ontario.</p>
                    <p>&ldquo;The impact of this Community Building Fund grant cannot be underestimated,&rdquo; said John Uildersma, President of Caledonia Minor Hardball. &ldquo;Expanding the use of the fields during the evening will allow users to participate in physical activities in a safe manner all season.&rdquo;</p>
                    <p>Caledonia Minor Hardball, throughout its history of volunteers, is committed to preserving the green space at Henning Park and ensuring that it provides a safe environment to enjoy sporting activities for many years to come. If you would like to learn more about the Caledonia Minor Hardball, please visit our website at www.henningpark.com.</p>
                    <p>The Ontario Trillium Foundation (OTF) is an agency of the Government of Ontario, and one of Canada&rsquo;s leading granting foundations. Last year, nearly $112M was invested into 1,384 community projects and partnerships to build healthy and vibrant communities and strengthen the impact of Ontario&rsquo;s non‐profit sector. In 2020/21, OTF supported Ontario&rsquo;s economic recovery by helping non‐profit organizations rebuild and recover from the impacts of COVID‐19. Visit <a href="https://www.otf.ca">otf.ca</a> learn more.</p>
                    <p><br></p>
                    <p><strong>Contact for Media Inquiries</strong></p>
                    <p>John Uildersma</p>
                    <p>President, Caledonia Minor Hardball</p>
                    <p><a href="mailto:johnnyu99@rogers.com">johnnyu99@rogers.com</a>&nbsp;</p>
                </div>
            </div> -->
        </div>
    </div>    
</template>

<script>


export default {
    data() {
        return {

        }
        
    },
}
</script>
