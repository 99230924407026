import { createWebHistory, createRouter } from "vue-router";
import Home from "@/Home.vue";
import Leagues from "@/Leagues.vue";
import Tournaments from "@/Tournaments.vue";
import FallBall from "@/FallBall.vue";
import BecomeASponsor from "@/BecomeASponsor.vue";
import BecomeAnUmpire from "@/BecomeAnUmpire.vue";
import PrivacyPolicy from "@/PrivacyPolicy.vue";
import ContactUs from "@/ContactUs.vue";
import Donate from "@/Donate.vue";
import Storefront from "@/Storefront.vue";
import TrilliumFoundation from '@/TrilliumFoundation.vue'
import ExternalRedirect from '@/ExternalRedirect.vue'
import StreamComponent from '@/StreamComponent.vue'
import PageNotFound from "@/PageNotFound.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: '/5050',
        component: ExternalRedirect
    },
    {
        path: '/stream-test', component: StreamComponent, name: 'StreamComponent'
    },
    {
        path: '/leagues', component: Leagues, name: 'Leagues'
    },
    {
        path: '/tournaments', component: Tournaments, name: 'Tournaments'
    },
    {
        path: '/fall-ball', component: FallBall, name: 'Fall Ball'
    },
    {
        path: '/become-an-umpire', component: BecomeAnUmpire, name: 'Become an Umpire'
    },
    {
        path: '/become-a-sponsor', component: BecomeASponsor, name: 'BecomeASponsor'
    },
    {
        path: '/storefront', component: Storefront, name: 'Storefront'
    },
    {
        path: '/contact-us', component: ContactUs, name: 'ContactUs'
    },
    {
        path: '/donate', component: Donate, name: 'Donate'
    },
    {
        path: '/trillium-foundation', component: TrilliumFoundation, name: 'TrilliumFoundation'
    },
    {
        path: '/privacy-policy', component: PrivacyPolicy, name: 'PrivacyPolicy'
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;