<template>
	<div>
		<div class="upcoming-at-the-park header-park">
			<div class="wrapper">
				<div class="upcoming-header">
					Our Leagues
				</div>
			</div>
		</div>
		<div class="main-content">
			<div class="wrapper">
				
				<div class="row">
					<div class="col-md-4">
						<div :class="['selection-square', { selected: league_selected === '2023Mens'}]" v-on:click="league_selected = 'henning-mens-2024'">
							<div class="selection-square-season">
								Summer
							</div>
							<div class="selection-square-gender">
								Mens 30+ Slopitch
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<!-- <div :class="['selection-square', { selected: league_selected === '2023Ladies'}]" v-on:click="league_selected = '2023Ladies'">
							<div class="selection-square-season">
								Summer
							</div>
							<div class="selection-square-gender">
								Ladies 3-Pitch
							</div>
						</div> -->
					</div>
					<div class="col-md-4">
						<!-- <div :class="['selection-square', { selected: league_selected === '3PITCH'}]" v-on:click="league_selected = '3PITCH'">
							<div class="selection-square-season">
								Summer
							</div>
							<div class="selection-square-gender">
								Coed 3-Pitch
							</div>
						</div> -->
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div :class="['selection-square', { selected: league_selected === 'SUNDAY23'}]" v-on:click="league_selected = 'sunday-lit-af-2024'">
							<div class="selection-square-season">
								Summer
							</div>
							<div class="selection-square-gender">
								Coed Slopitch
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<!-- <div :class="['selection-square', { selected: league_selected === 'TUESDAY23'}]" v-on:click="league_selected = 'TUESDAY23'">
							<div class="selection-square-season">
								Summer
							</div>
							<div class="selection-square-gender">
								Coed Slopitch
							</div>
						</div> -->
					</div>
				</div>

				<div v-if="league_selected !== null" >
					<div class="league-page" v-if="league_selected === 'fall-ball'">
						<div class="league-page-header">
							Fall Ball
						</div>
					</div>
					<div v-else>
						<div class="league-page" v-if="event">
							<a :href="`https://slopitchcentral.com/league/${event.slug}/schedule`"><img src="https://cdn.blacktiecollab.com/gamebeast.io/slopitch.png" style="max-width: 500px; margin: 0 auto; display: block;" alt=""></a>
							<br><br>
							<div class="league-page-header">
								{{ event.name }}
							</div>
							<div class="league-page-content">
								<div class="row">
									<div class="col-md-6">
										<div :class="['selection-square', { selected: page === 'Schedule'}]" v-on:click="page = 'Schedule'">
											<div class="selection-square-season">
												Schedule
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div :class="['selection-square', { selected: page === 'Standings'}]" v-on:click="page = 'Standings'">
											<div class="selection-square-season">
												Standings
											</div>
										</div>
									</div>
									<!-- <div class="col-md-4">
										<div :class="['selection-square', { selected: page === 'Teams'}]" v-on:click="page = 'Teams'">
											<div class="selection-square-season">
												Teams
											</div>
										</div>
									</div> -->
								</div>

								<div v-if="teams && page === 'Standings'">
									<div class="panel">
										<div class="panel-heading">
											{{ teams[0].division_name }}
										</div>
										<div class="panel-content">
											<table class="table">
												<tr class="table-header">
													<td>Team Name</td>
													<td>Wins</td>
													<td>Losses</td>
													<td>Ties</td>
													<td>Points</td>
													<td>Runs For</td>
													<td>Runs Against</td>
												</tr>
												<tr class="standard-table-row" v-for="(team, key) of orderTeamsInDivision(teams)" :key="key">
													<td>{{ team.name }}</td>
													<td>{{ team.wins }}</td>
													<td>{{ team.losses }}</td>
													<td>{{ team.ties }}</td>
													<td>{{ team.points }}</td>
													<td>{{ team.runs_for }}</td>
													<td>{{ team.runs_against }}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
								<!-- <div v-if="teams && page === 'Teams'">
									<div class="panel">
										<div class="panel-heading">
											{{ teams[0].division_name }}
										</div>
										<div class="panel-content">
											<table class="table">
												<tr class="table-header">
													<td>Team Name</td>
													<td>Coach Name</td>
												
												</tr>
												<tr class="standard-table-row" v-for="(team, key) of orderTeamsInDivision(teams)" :key="key">
													<td>{{ team.name }}</td>
													<td>{{ team.coach_full_name }}</td>
												</tr>
											</table>
										</div>
									</div>
								</div> -->
								<!-- <div v-if="teams && page === 'Announcements'">
									Announcements
								</div> -->
								<div v-if="games && page === 'Schedule'">
									<div class="panel"  v-for="(game, key) in games" :key="key">
										<div class="panel-heading">
											{{ game.date }}
										</div>
										<div class="panel-content">
											<table class="table">
												<tr class="table-header">
													<td>Time</td>
													<td>Park Name</td>
													<td>Diamond</td>
													<td style="min-width: 90px;">Division</td>
													<td style="min-width: 120px; text-align: right;">Away Team</td>
													<td colspan="2" style="min-width: 20px;">Score</td>
													<td style="min-width: 120px;">Home Team</td>
													<!-- <td>Report</td> -->
												</tr>
												<tr v-for="(row, key) in game.games" :key="key" :class="['standard-table-row', { reported: row.score_reported === 1 }]">
													<td>
														{{ moment(row.start_time, 'hh:mm:ss').format('hh:mma') }} - {{ moment(row.end_time, 'hh:mm:ss').format('hh:mma') }} 
													</td>
													<td>
														{{ row.park_name }}
													</td>
													<td>
														{{ row.diamond_signifier }}
													</td>
													<td>
														{{ row.division_id }}
													</td>
													<td style="text-align: right" v-if="row.awayTeam">
														<b v-if="row.away_team_score > row.home_team_score">{{ row.awayTeam.name }}</b>
														<span v-else>{{ row.awayTeam.name }}</span>
													</td>
													<td v-if="row.away_team_freetext">
														{{ row.away_team_freetext }}
													</td>
													<td>
														<b v-if="row.away_team_score > row.home_team_score">{{ row.away_team_score }}</b>
														<span v-else>{{ row.away_team_score }}</span>
													</td>
													<td>
														<b v-if="row.away_team_score < row.home_team_score">{{ row.home_team_score }}</b>
														<span v-else>{{ row.home_team_score }}</span>
													</td>
													<td v-if="row.homeTeam">
														<b v-if="row.away_team_score < row.home_team_score">{{ row.homeTeam.name }}</b>
														<span v-else>{{ row.homeTeam.name }}</span>
													</td>
													<td v-if="row.home_team_freetext">
														{{ row.home_team_freetext }}
													</td>
													<!-- <td><a v-if="row.score_reported === 0" :href="`https://slopitchcentral.com/leagues/${event.slug}/schedule?game=${row.id}`">Report This Score</a></td> -->
												</tr>
											</table>
											
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
	data() {
		return {
			leagues: [],
			league_selected: 'henning-mens-2024',
			events: [],
			upcoming_games: [],
			team_selected: null,
			event: null,
			teams: [],
			games: [],
			page: 'Schedule'
		};
	},
	components: {
		
	},
	mounted: function() {
		var vm = this
		
		axios.get('https://api.slopitchcentral.com/api/find-league/henning-mens-2024').then(results => {
			console.log('results', results)
			vm.event = results.data.event
			vm.teams = results.data.event.teams

			var i = 0

			for(i; i < vm.teams.length; i++) {
				vm.teams[i].points = (vm.teams[i].wins * 2) + vm.teams[i].ties
				vm.teams[i].runs_diff = vm.teams[i].runs_for - vm.teams[i].runs_against
			}
		})

		axios.get('https://api.slopitchcentral.com/api/get-games/henning-mens-2024').then(results => {
			console.log('results', results)
			vm.games = results.data.games
		})
	},
	watch: {
		'league_selected': function(newValue) {
			var vm = this
			axios.get(`https://api.slopitchcentral.com/api/find-league/${newValue}`).then(results => {
				console.log('league_selected', results)
				vm.event = results.data.event
				vm.teams = results.data.event.teams

				var i = 0

				for(i; i < vm.teams.length; i++) {
					vm.teams[i].points = (vm.teams[i].wins * 2) + vm.teams[i].ties
					vm.teams[i].runs_diff = vm.teams[i].runs_for - vm.teams[i].runs_against
				}
			})

			axios.get(`https://api.slopitchcentral.com/api/get-games/${newValue}`).then(results => {
				console.log('league_selected', results)
				vm.games = results.data.games
			})
		}
	},
	computed: {
		selectedLeague: function() {
            var vm = this
            var league = _.find(vm.leagues, function(league) {
                return league.slug === vm.league_selected
            })
            
            return league
        },
		selectedSchedule: function() {
            var vm = this

            var events = _.filter(vm.events, function(event) {
                return event.league_slug === vm.league_selected
            })
            
            if(vm.team_selected) {
                events = _.filter(vm.events, function(event) {
                    if(event.away_team == vm.team_selected.team_name) {
                        return true
                    }
                    if(event.home_team == vm.team_selected.team_name) {
                        return true
                    }
                    return false
                })
            }

            return events
        },
        selectedUpcomingGames: function() {
            var vm = this

            var upcoming_games = _.filter(vm.upcoming_games, function(event) {
                return event.league_slug === vm.league_selected
            })
            
            if(vm.team_selected) {
                upcoming_games = _.filter(vm.upcoming_games, function(event) {
                    if(event.away_team == vm.team_selected.team_name) {
                        return true
                    }
                    if(event.home_team == vm.team_selected.team_name) {
                        return true
                    }
                    return false
                })
            }

            return upcoming_games
        },
        selectedLeagueStandings: function() {
            var vm = this

            var standings = vm.selectedLeague.standings

            var i = 0

            for (i; i < standings.length; i++) {
                standings[i].points = (standings[i].wins * 2) + standings[i].ties
                standings[i].run_diff = standings[i].rf - standings[i].ra
            }
            console.log('standings', standings)
            standings = _.orderBy(standings, ['points', 'run_diff'], ['desc', 'desc'])

            return standings
        }
	},
	methods: {
		moment: function(date, format = null){
			return moment(date, format)
		},
		orderTeamsInDivision: function(teams) {
			var ordered = _.orderBy(teams, ['points', 'run_diff'], ['desc', 'desc'])
        return ordered
      }
	}
}
</script>

<style lang="scss">
    // @import './styles/Welcome.scss';
</style>
