import { defineStore } from 'pinia'

export const useAdStore = defineStore('ad', {
  state: () => ({
    shouldRefreshAds: false
  }),
  actions: {
    triggerAdRefresh() {
      this.shouldRefreshAds = true
    },
    resetAdRefresh() {
      this.shouldRefreshAds = false
    }
  }
})