<template>
	<div class="app">
		<Header></Header>
		<router-view />
		
		
		<Slides></Slides>

		<div class="footer">
			<div class="wrapper">
				<a href="https://teamgear.ca"><img src="https://cdn.blacktiecollab.com/teamgear.ca/teamgearadbanner copy.png" style="max-width: 1000px; margin: 0 auto; width: 100%; display: block;" alt=""></a>
				<br><br>
				<div class="row">
					<div class="col-md-3">
						<a href="https://www.blacktiecollab.com" target="_blank">
							Donated & Powered By Blacktie Collab
						</a>
					</div>
					<div class="col-md-6">
						<div class="row" style="margin-top: 10px;">
							<div class="col-md-6">
								<b>HENNING PARK</b>
							</div>
							<div class="col-md-6" style="text-align: right;">
								905-906-3025
							</div>
						</div>
						<div class="row">
							<div class="col-md-12" style="text-align: center;">
								161 Greens Road, Caledonia - 10 Minutes From Hamilton, Caledonia & 30 Minutes From Dunnville & Cayuga
							</div>
						</div>
					</div>
					<div class="col-md-3" style="text-align: right;">
						<img src="https://cdn.blacktiecollab.com/henningpark.com/henningpark.png" height="120" alt="">
					</div>
				</div>
			</div>
		</div>
		<RampView
			:pwUnits="[
				// { type: 'corner_ad_video' },
				{ type: 'in_content' },
				{ type: 'sticky_sidebar' },
				{ type: 'bottom_rail' },
				{ type: 'left_rail' },
				{ type: 'right_rail' },
				{ type: 'corner_ad_video' },
				// { type: 'leaderboard_atf', selectorId: 'pwLeaderboardAtf' },
				// { type: 'med_rect_atf', selectorId: 'medRectAtf' },
				// { type: 'leaderboard_btf', selectorId: 'pwDashboard2' },
				
			]"
			:PUB_ID="'1025306'"
			:WEBSITE_ID="'75041'"
		></RampView>
	</div>
</template>

<script>
import Slides from './Slides.vue';
import Header from './Header.vue';
import RampView from './RampView.vue';

export default {
	path: '/',
	data() {
		return {
			gallery: 0,
			menuNav: false,
			
		};
	},
	mounted: function() {
		var vm = this
	},
	components: {
		Slides,
		Header,
		RampView
	}
	
}
</script>

<style lang="scss">
	@import './assets/scss/Reset.scss';
	@import './assets/scss/Grid.scss';
  @import './assets/scss/App.scss';
</style>

