<template>
	<div>
        <div class="upcoming-at-the-park" style="min-height: 200px;">
			<div class="wrapper">
				<div class="upcoming-header">
					Contact Us
				</div>
			</div>
		</div>
		<div class="field-conditions-and-map">
			<div class="wrapper">
				<div class="row">
					<div class="col-md-6">
						<iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJD7coOrKNLIgRyf-0HMuHrKU&key=AIzaSyC39S8HbzJUpSsZFN7ZXYDTOQAM6Ybke_c" allowfullscreen></iframe>
					</div>
					<div class="col-md-6">
						<div class="contact-us">
							<h1><b>Henning Park</b></h1>
							<p>161 Greens Road, Caledonia</p>
							
							<br><br>
							<p><b>President:</b> John Uildersma</p>
							<p><b>By Phone:</b> 905-906-3025</p>
							<p><b>By Email:</b> johnnyu99&#64;rogers.com</p>
							<hr>
							<p><b>Vice President:</b> Dave Corlis</p>
							<p><b>Treasurer:</b> Kat Meahan</p>
							<p><b>Secretary:</b> Larry McKay</p>
							<p><b>Director of Maintenance:</b> Gord Palmer</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
	data() {
		return {
			test: null
		};
	},
	components: {

	},
	watch: {
		
	},
	mounted: function() {
		var vm = this
		
		
	},
	computed: {
	
	},
	methods: {
	
	}
}
</script>

<style lang="scss">
    // @import './styles/Welcome.scss';
</style>
