<template>
    <div>
        <iframe data-tally-src="https://tally.so/r/wbqx40?transparentBackground=1" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0" title="Donate To Henning Park"></iframe>
    </div>    
</template>

<script>


export default {
    data() {
        return {

        }
        
    },
}
</script>
