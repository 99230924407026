<template>
    <div class="header">
        <div class="red-bar">
            HOME OF THE CAL BEARS
        </div>
        <div class="grey-bar">
            <div class="logo">
                <a href="/">
                    <img src="https://cdn.blacktiecollab.com/henningpark.com/2023/henningpark.png" alt="">
                </a>
            </div>
            <div class="navigation desktop">
                <ul>
                    <li v-for="item of navigation" :key="item.url" :class="item.class">
                        <a :href="item.url">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
            <div class="navigation mobile">
                <ul>
                    
                    <li class="right mobile">
                        <a v-on:click="menuNav = true">Menu</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="second-navigation desktop">
            <ul>
                <li v-for="item of secondNavigation" :key="item.url" :class="item.class">
                    <a :href="item.url">{{ item.name }}</a>
                </li>
            </ul>
        </div>
        <div class="menu-navigation" v-if="menuNav">
                <ul>
                    <li v-on:click="menuNav = false">
                        <a>Close</a>
                    </li>
                    <li v-for="item of navigation" :key="item.url" :class="item.class">
                        <a :href="item.url">{{ item.name }}</a>
                    </li>
                    <li v-for="item of secondNavigation" :key="item.url" :class="item.class">
                        <a :href="item.url">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            menuNav: false,
            navigation: [
                {
                    url: '/',
                    name: 'Home'
                },
                {
                    url: '/leagues',
                    name: 'Leagues'
                },
                {
                    url: '/tournaments',
                    name: 'Tournaments'
                },
                {
                    url: '/fall-ball',
                    name: 'Fall Ball'
                },
                {
                    url: '/become-an-umpire',
                    name: 'Become an Umpire'
                },    
                {
                    url: '/5050',
                    name: '50-50'
                },  
            ],
            secondNavigation: [
                {
                    url: '/become-a-sponsor',
                    name: 'Sponsorship'
                },
                {
                    url: '/storefront',
                    name: 'Storefront',
                },
                {
                    url: '/donate',
                    name: 'Donate'
                },
                {
                    // url: 'https://cdn.blacktiecollab.com/henningpark.com/Media Release - Caledonia Minor Hardball (OTF Final).docx',
                    url: '/trillium-foundation',
                    name: 'Trillium Foundation',
                },
                {
                    url: '/contact-us',
                    name: 'Contact Us'
                },
                {
                    url: '/privacy-policy',
                    name: 'Privacy Policy'
                },
                
            ]
        }
        
    },
}
</script>
