<template>
	<div>
		<div class="upcoming-at-the-park header-park" style="min-height: 200px;">
			<div class="wrapper">
				<div class="upcoming-header">
					Become an Umpire
				</div>
				
			</div>
		</div>
		<div class="main-content sponsor">
			<div class="wrapper">
			
				<img src="/assets/img/7.jpg" width="100%" alt="">
				<div class="text-wrapper">
					<p>Becoming an umpire for the <b>Henning Park Umpire Program</b> is an excellent opportunity for those who are passionate about sports and enjoy being in a leadership role. Whether you are interested in umpiring hardball (12+ years old) or slo-pitch (18+ years old), <b>Henning Park Umpire Program</b>, welcomes you to join their team. The program aims to provide a safe and fun environment for players, coaches, and umpires.</p>

                    <p>One of the benefits of joining the <b>Henning Park Umpire Program</b> is that the program rebates umpires for any testing fees after they have umpired a few games. This means that umpires can start earning money right away and not have to worry about covering any upfront costs. Furthermore, the program is intended to revolutionize umpiring programs by creating a path to more money and more games. The program provides ample opportunities for umpires to improve their skills and increase their earnings.</p>

                    <p>At <b>Henning Park</b>, the umpire program fosters a respectful community that will treat umpires with respect. Umpires play a critical role in ensuring a fair and safe game for everyone, and <b>Henning Park</b> recognizes the importance of treating umpires with the respect they deserve. The program provides a supportive environment that encourages umpires to learn and grow, both personally and professionally.</p>

                    <p>In summary, if you are looking for a rewarding and challenging experience, becoming an umpire for the <b>Henning Park Umpire Program</b> is an excellent opportunity. With rebates for testing fees, opportunities for growth and development, and a supportive community, the program offers an exceptional experience for umpires. Joining the <b>Henning Park Umpire Program</b> can be the first step towards a career as an umpire or a way to get involved in the community and give back.</p>

                    <p><b>Click the link below and fill out the form to become an umpire:</b></p>
					<a href="https://tally.so/r/nW2Mvv">Become an Umpire</a>
                    <!-- <iframe data-tally-src="https://tally.so/r/nW2Mvv?transparentBackground=1" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0" title="Become an Umpire"></iframe> -->
                    
				</div>
				
		
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
	data() {
		return {
			test: null
		};
	},
	components: {

	},
	watch: {
		
	},
	mounted: function() {
		var vm = this
		
		
	},
	computed: {
	
	},
	methods: {
	
	}
}
</script>

<style lang="scss">
    // @import './styles/Welcome.scss';
</style>
