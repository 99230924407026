<template>
    <div class="gallery">
        
        <div class="wrapper">
            <h1>Photo Gallery</h1>
            <carousel :items-to-show="1.5" :autoplay="2000">
                <slide v-for="slide of slides" :key="slide" >
                    <div class="slide-image" :style="`background: url(${slide}) center center; background-size: cover`">
                        <!-- <img :src="slide" class="gallery-img" alt=""> -->
                    </div>
                </slide>

                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>
    
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    data() {
        return {
        slides: [
				'https://cdn.blacktiecollab.com/henningpark.com/IMG_0657.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/IMG_0664.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/IMG_0665.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/IMG_0666.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/1.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/2.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/3.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/4.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/5.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/6.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/7.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/8.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/9.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/10.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/11.jpg',
				'https://cdn.blacktiecollab.com/henningpark.com/12.jpg',

			]
        }
    },
    components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
}
</script>
