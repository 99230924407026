<template>
	<div>
		<div id="application" v-cloak>
			<div class="upcoming-at-the-park">
				<div class="wrapper">
					<div class="upcoming-header">
						The Best Place to Play Ball in <u>Ontario.</u>
					</div>
					

				</div>
			</div>
			<div class="main-content sponsor">
				<div class="wrapper">
					<h1>
						Henning Park Stanley Cup Playoff Pool
					</h1>
					<div class="text-padding">
						<p>Download the "Office Pools" app and join the 2024 Stanley Cup playoffs box pool named "Henning Park" using the password "slopitch." Follow the instructions to select your team. The selection boxes will be updated once the playoff teams are finalized.</p>

						<p>Scoring rules are as follows: Each skater (forward and defenseman) earns one point for either a goal or an assist. An additional two points are awarded for either an overtime goal or a shorthanded goal. Goaltenders earn one point for a goal or an assist and another for a win. A shutout earns three points.</p>

						<p>The entry fee is $25, with $12.50 contributing to park maintenance and $12.50 allocated to the prize pool. The total prize amount will be determined by the number of entries as of the April 20th, 7 PM deadline. To be eligible, ensure your entry fee is e-transferred to caledoniahenningpark@gmail.com before the deadline. No exceptions will be made.</p>

					</div>
				</div>
				<img src="https://cdn.blacktiecollab.com/henningpark.com/IMG_8607.png" width="100%" alt="">
				<img src="https://cdn.blacktiecollab.com/henningpark.com/CleanShot 2024-04-24 at 19.24.06@2x.png" width="100%" alt="">
				<img src="https://cdn.blacktiecollab.com/henningpark.com/CleanShot 2024-04-24 at 18.52.51@2x.png" width="100%" alt="">
				<img src="" alt="">
				<div class="wrapper">
					<h1>
						Gord Palmer, 1947-January 5, 2023
					</h1>
					<div class="text-padding">
						<p>The Palmer family would like to express a heartfelt thank you to each, and every person who attended the beautiful celebration of life in honor of their father/grandfather/great grandfather and best friend Gord.  All the shared memories, flowers and meals that have been shared with us were thoroughly appreciated.</p>
						<p>Your support and kind words have made this difficult time much easier.</p>
						<p>With community support, we know that Gord's memory will live on at Henning Park. We appreciate all of you.</p>
						<hr>
						<p>PALMER, Gordon Roy George James August 1, 1947 - January 5, 2023. With the saddest of hearts, the family of Gordon Palmer, is announcing the man, the myth, the legend, has gone on to greener pastures as of January 5, 2023. </p>
						<p>He is no longer to be found at Henning Park, except in spirit. Predeceased by his ex-wife and mother of his children Linda Grills. Beloved father of Patrick (Grace Fanara), Kory (Melanie Smith), Keitha (John Lindstead) and Lauretta (Paul Kelly). Adored grandfather of Rebecca (Stephen Babic), David, Tanner, William, Spencer, Matthew, Hannah and Nicholas. Spoiling privileges as great-grandfather of Jack and Cynthia. Son of the late Roy and Jean Palmer of Dundas, Ontario. Survived by his brothers Louis/Lewis(Charmaine) and George (Janie). Moose, his canine sidekick, misses him deeply, including his rides in the Cadillac to the local Tim Horton's. Gord was a previous employee of Orenstein & Koppel (O&K) before relocating to Caledonia after suffering a work related injury.</p>
						<p>He has been actively involved as a player and umpire with baseball organizations in Dundas and then Caledonia. Gord has spent numerous hours of volunteer and hard work at Henning Ball Park where he has been part of the association as President, and general “go-to” guy. If he wasn't at home, Gord could always be found at the ballpark.  His proudest role was that of father, ensuring his children were provided for, driven to numerous sporting events and being their loudest cheerleaders.  He was blessed with grandchildren and great-grandchildren that ensured he was forever young at heart and always busy.  His heart was overwhelmed at the end and ours are forever broken.  The Family extends thanks to the many nurses and doctors of the Cardiac Care Unit at the Hamilton General Hospital for making Gord's final days as comfortable and dignified as possible.  In lieu of flowers, donations would be appreciated in Gord's name to the Caledonia Minor Hardball Association or any local baseball organization that benefits and promotes the development of baseball skills for children.  Spreading the joy of the game.</p>
						<p><b>We will all miss you deeply, Gord.</b></p>
					</div>
				</div>
				<div class="wrapper">
					<h1>
						About Henning Park
					</h1>
					<div class="text-padding">
						<p>Henning Park is a place of great pride for the community of Caledonia. Since its opening, the park has been dedicated to providing a space for the youth of Caledonia to practice and play baseball. The park is a not-for-profit, privately-owned facility that relies on the generosity of the community to keep its doors open.</p>
						<p>The park boasts of six diamonds that gleam under the bright sun, nestled amidst lush greenery. The park's leadership team is always looking for new ways to raise funds and keep the park running smoothly. Henning Park is a place where memories are made and dreams are realized, and it is with the support of the community that it continues to thrive.</p>
					</div>
				</div>
			</div>
			
			
			<div class="field-conditions-and-map">
				<div class="wrapper">
					<div class="map">
						<h1>Field Conditions & Map</h1>
						<div class="map-content">
							<img src="https://cdn.blacktiecollab.com/henningpark.com/Henning Park Map-01.jpg"  alt="">
							<div class="diamond-box diamond-1">
								<div class="diamond-header">
									Jim Lawrence D1
								</div>
								<span v-if="diamonds">
									<div :class="['diamond-status', { open: diamonds.diamond_1 === 1}, { closed: diamonds.diamond_1 === 0 }]" >
										{{ diamonds.diamond_1 === 1 ? 'Open' : 'Closed' }}
									</div>
								</span>
							</div>
							<div class="diamond-box diamond-2">
								<div class="diamond-header">
									Jen Meahan D2
								</div>
								<div :class="['diamond-status', { open: diamonds.diamond_2 === 1}, { closed: diamonds.diamond_2 === 0 }]" >
									{{ diamonds.diamond_2 === 1 ? 'Open' : 'Closed' }}
								</div>
							</div>

							<div class="diamond-box diamond-3">
								<div class="diamond-header">
									Diamond 3
								</div>
								<div :class="['diamond-status', { open: diamonds.diamond_3 === 1}, { closed: diamonds.diamond_3 === 0 }]" >
									{{ diamonds.diamond_3 === 1 ? 'Open' : 'Closed' }}
								</div>
							</div>

							<div class="diamond-box diamond-4">
								<div class="diamond-header">
									Paul & Travis D4
								</div>
								<div :class="['diamond-status', { open: diamonds.diamond_4 === 1}, { closed: diamonds.diamond_4 === 0 }]" >
									{{ diamonds.diamond_4 === 1 ? 'Open' : 'Closed' }}
								</div>
							</div>
							<div class="diamond-box diamond-5">
								<div class="diamond-header">
									Diamond 5
								</div>
								<div :class="['diamond-status', { open: diamonds.diamond_5 === 1}, { closed: diamonds.diamond_5 === 0 }]" >
									{{ diamonds.diamond_5 === 1 ? 'Open' : 'Closed' }}
								</div>
							</div>
							<div class="diamond-box diamond-6">
								<div class="diamond-header">
									Diamond 6
								</div>
								<div :class="['diamond-status', { open: diamonds.diamond_6 === 1}, { closed: diamonds.diamond_6 === 0 }]" >
									{{ diamonds.diamond_6 === 1 ? 'Open' : 'Closed' }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
	data() {
		return {
			fileCSV: 'test',
			csvArray: [],
			upcoming_dates: [],
			viewAllSelectedKey: null,
			diamonds: { diamond_1: 1, diamond_2: 1, diamond_3: 1, diamond_4: 1, diamond_5: 1, diamond_6: 1 },
			gallery: 0
		};
	},
	components: {

	},
	watch: {
		
	},
	mounted: function() {
		var vm = this
		
		axios.get('/api/upcoming-dates').then(results => {
			if(results.data.success === true) {
				vm.upcoming_dates = results.data.upcoming_dates
			}
		})

		axios.get('/api/diamonds').then(results => {
			if(results.data.success === true) {
				vm.diamonds = results.data.diamonds
			}
		})
	},
	computed: {
	
	},
	methods: {
	
	}
}
</script>

<style lang="scss">
    // @import './styles/Welcome.scss';
</style>
